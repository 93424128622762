<template>
  <div>
    <b-alert variant="success"
      fade
      v-bind:show="alertCountDownSeconds"
      v-on:dismiss-count-down="alertCountDownTick">
      System document updated successfully
    </b-alert>
    <div class="mb-3">
      <h5>Manage System Documents</h5>
    </div>

    <div class="text-center" v-if="isLoading">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <InlineDocumentChooser documentName="Help"
        v-on:downloadDocument="downloadHelpDocument"
        v-on:updateDocument="saveHelpDocument" />

      <InlineDocumentChooser documentName="Manual"
        v-on:downloadDocument="downloadManualDocument"
        v-on:updateDocument="saveManualDocument" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fileHandlerMixin from '../mixins/fileHandlerMixin';
import InlineDocumentChooser from '../components/InlineDocumentChooser.vue';

export default {
  name: 'SystemDocuments',
  components: { InlineDocumentChooser },
  mixins: [fileHandlerMixin],
  data() {
    return {
      helpEditFile: null,
      helpEditIsVisible: false,
      alertCountDownSeconds: 0,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.systemDocuments.isLoading
      || this.$store.state.systemDocuments.isDownloadingFile;
    },
  },
  methods: {
    ...mapActions('systemDocuments', ['getHelpDocument', 'getManualDocument', 'updateHelpDocument', 'updateManualDocument']),
    async downloadHelpDocument() {
      await this.getHelpDocument()
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async saveHelpDocument(helpFile) {
      await this.updateHelpDocument(helpFile)
        .then(() => {
          this.alertCountDownSeconds = 2;
        })
        .catch((error) => { this.handleHttpError(error); });
    },
    async downloadManualDocument() {
      await this.getManualDocument()
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async saveManualDocument(manualFile) {
      await this.updateManualDocument(manualFile)
        .then(() => {
          this.alertCountDownSeconds = 2;
        })
        .catch((error) => { this.handleHttpError(error); });
    },
    alertCountDownTick(dismissCountDown) {
      this.alertCountDownSeconds = dismissCountDown;
    },
  },
};
</script>
