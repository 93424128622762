<template>
    <b-alert v-bind:show="showBroadcastMessage"
      class="border border-secondary"
      fade
      v-bind:variant="getBroadcastMessageVariant">
      <div v-if="systemSettings">
        <h4>
          <b-icon-info-circle v-if="getBroadcastMessageVariant === 'info'" />
          <b-icon-exclamation-triangle v-if="getBroadcastMessageVariant === 'warning'" />
          <b-icon-exclamation-triangle v-if="getBroadcastMessageVariant === 'danger'" />
          {{systemSettings.broadcastMessageTitle}}
        </h4>
        <p>
          {{systemSettings.broadcastMessageBody}}
        </p>
      </div>
    </b-alert>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'BroadcastMessage',
  async created() {
    await this.getSystemSettings();
  },
  computed: {
    ...mapState('systemSettings', ['isLoading']),
    ...mapGetters('systemSettings', ['getBroadcastMessageVariant']),
    systemSettings() {
      return this.$store.state.systemSettings.systemSettings;
    },
    showBroadcastMessage() {
      return this.systemSettings?.broadcastMessageIsEnabled;
    },
  },
  methods: {
    ...mapActions('systemSettings', ['getSystemSettings']),
  },
};
</script>
