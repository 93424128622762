<template>
  <b-form-group
    label-cols-lg="2"
    content-cols-lg="10"
    v-bind:label="labelText">
    <b-form-select
      v-model="selectedDropdownItem"
      v-on:change="selectedDropdownItemChanged"
      v-bind:disabled="isDisabled"
      v-bind:required="preventSelectDefaultItem">
      <b-form-select-option v-if="preventSelectDefaultItem" v-bind:value="null" disabled>
        Please select a {{labelText}}:
      </b-form-select-option>
      <b-form-select-option v-else v-bind:value="null">
        All
      </b-form-select-option>
      <b-form-select-option v-for="dropdownItem in dropdownItems"
        v-bind:key="dropdownItem.id"
        v-bind:value="dropdownItem.id">
        {{dropdownItem.name}}
      </b-form-select-option>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: 'CatalogueDropdown',
  props: {
    for: {
      type: String,
      validator(value) {
        return ['items', 'itemfirstletters', 'itemwordlengths', 'lettercases', 'organisations', 'sections', 'tasktypes', 'wordcategories', 'worksheets'].indexOf(value.toLowerCase()) !== -1;
      },
    },
    preventSelectDefaultItem: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (!this.dropdownItems?.some((s) => s.id === this.selectedDropdownItem)) {
      this.selectedDropdownItem = null;
    }
  },
  computed: {
    labelText() {
      switch (this.for.toLowerCase()) {
        case 'items':
          return 'Item';
        case 'itemfirstletters':
          return 'Item First Letter';
        case 'itemwordlengths':
          return 'Item Word Length';
        case 'lettercases':
          return 'Letter Case';
        case 'sections':
          return 'Section';
        case 'tasktypes':
          return 'Task Type';
        case 'wordcategories':
          return 'Word Category';
        default:
          return '';
      }
    },
    dropdownItems() {
      switch (this.for.toLowerCase()) {
        case 'items':
          return this.$store.state.items.items;
        case 'itemfirstletters':
          return this.$store.state.catalogue.itemFirstLetters;
        case 'itemwordlengths':
          return this.$store.state.catalogue.itemWordLengths;
        case 'lettercases':
          return this.$store.state.cases.cases;
        case 'sections':
          return this.$store.state.sections.sections;
        case 'tasktypes':
          return this.$store.state.taskTypes.taskTypes;
        case 'wordcategories':
          return this.$store.state.wordCategories.wordCategories;
        default:
          return null;
      }
    },
    selectedDropdownItem: {
      get() {
        switch (this.for.toLowerCase()) {
          case 'items':
            return this.$store.state.catalogue.selectedItemId;
          case 'itemfirstletters':
            return this.$store.state.catalogue.selectedItemFirstLetter;
          case 'itemwordlengths':
            return this.$store.state.catalogue.selectedItemWordLength;
          case 'lettercases':
            return this.$store.state.catalogue.selectedCaseId;
          case 'sections':
            return this.$store.state.cart.cart.sectionId;
          case 'tasktypes':
            return this.$store.state.cart.cart.taskTypeId;
          case 'wordcategories':
            return this.$store.state.catalogue.selectedWordCategoryId;
          default:
            return null;
        }
      },
      set(newValue) {
        switch (this.for.toLowerCase()) {
          case 'items':
            this.$store.dispatch('catalogue/changeSelectedItem', newValue);
            break;
          case 'itemfirstletters':
            this.$store.dispatch('catalogue/changeSelectedItemFirstLetter', newValue);
            break;
          case 'itemwordlengths':
            this.$store.dispatch('catalogue/changeSelectedItemWordLength', newValue);
            break;
          case 'lettercases':
            this.$store.dispatch('catalogue/changeSelectedCase', newValue);
            break;
          case 'sections':
            this.$store.dispatch('cart/changeSelectedSection', newValue);
            break;
          case 'tasktypes':
            this.$store.dispatch('cart/changeSelectedTaskType', newValue);
            break;
          case 'wordcategories':
            this.$store.dispatch('catalogue/changeSelectedWordCategory', newValue);
            break;
          default:
            break;
        }
      },
    },
    isDisabled() {
      switch (this.for.toLowerCase()) {
        case 'items':
          return !this.$store.state.cart.cart.isCartContextConfirmed
          || this.$store.state.catalogue.selectedWordCategoryId === null
          || this.$store.state.items.isLoading;
        case 'itemfirstletters':
          return !this.$store.state.cart.cart.isCartContextConfirmed;
        case 'itemwordlength':
          return !this.$store.state.cart.cart.isCartContextConfirmed;
        case 'lettercases':
          return !this.$store.state.cart.cart.isCartContextConfirmed;
        case 'sections':
          return this.$store.state.cart.cart.isCartContextConfirmed;
        case 'tasktypes':
          return this.$store.state.cart.cart.sectionId === null
          || this.$store.state.taskTypes.isLoading
          || this.$store.state.cart.cart.isCartContextConfirmed;
        case 'wordcategories':
          return !this.$store.state.cart.cart.isCartContextConfirmed;
        default:
          return false;
      }
    },
  },
  methods: {
    selectedDropdownItemChanged() {
      switch (this.for.toLowerCase()) {
        case 'items':
          break;
        case 'itemfirstletters':
          break;
        case 'lettercases':
          break;
        case 'sections':
          this.$store.dispatch('cart/changeSelectedTaskType', null);
          this.$store.dispatch('catalogue/changeSelectedItemFirstLetter', null);
          this.$store.dispatch('catalogue/changeSelectedItemWordLength', null);
          this.$store.dispatch('catalogue/changeSelectedWordCategory', null);
          this.$store.dispatch('catalogue/changeSelectedItem', null);
          this.$store.dispatch('catalogue/changeSelectedCase', null);
          break;
        case 'tasktypes':
          this.$store.dispatch('catalogue/changeSelectedItemFirstLetter', null);
          this.$store.dispatch('catalogue/changeSelectedItemWordLength', null);
          this.$store.dispatch('catalogue/changeSelectedWordCategory', null);
          this.$store.dispatch('catalogue/changeSelectedItem', null);
          this.$store.dispatch('catalogue/changeSelectedCase', null);
          break;
        case 'wordcategories':
          this.$store.dispatch('catalogue/changeSelectedItem', null);
          break;
        default:
          break;
      }

      this.$emit('dropdownItemChanged');
    },
  },
};
</script>
