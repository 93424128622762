<template>
  <div>
    <div class="mb-3">
      <h5>Manage Sections - {{titleText}}</h5>
    </div>
    <div v-if="section">
      <b-form
        autocomplete="off"
        v-on:submit="formSubmit">
        <b-form-group label="Section Name:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-input
            v-model="section.name"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>
        <b-button class="mr-2"
          title="Cancel"
          v-bind:disabled="sectionsIsLoading"
          v-on:click="cancelClick">
          <b-icon-x />
        </b-button>
        <b-button class="mr-2" type="submit" variant="primary"
          v-bind:title="submitText"
          v-bind:disabled="sectionsIsLoading">
          <span v-if="isNew"><b-icon-plus /></span>
          <span v-else><b-icon-check /></span>
          <span v-if="sectionsIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'SectionDetails',
  props: {
    section: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.section) {
      this.$router.go(-1);
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    sectionsIsLoading() {
      return this.$store.state.sections.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add Section' : 'Edit Section';
    },
    submitText() {
      return this.new === true ? 'Add Section' : 'Update Section';
    },
  },
  methods: {
    ...mapActions('sections', ['createSection', 'updateSection']),
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createSection(this.section)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateSection(this.section)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
  },
};
</script>
