import Vue from 'vue';
import Vuex from 'vuex';

import accountModule from './modules/account.module';
import applicationSettingsModule from './modules/applicationsettings.module';
import authenticationModule from './modules/authentication.module';
import casesModule from './modules/cases.module';
import cartModule from './modules/cart.module';
import catalogueModule from './modules/catalogue.module';
import downloadablePacksModule from './modules/downloadablepacks.module';
import errorModule from './modules/error.module';
import itemsModule from './modules/items.module';
import organisationsModule from './modules/organisations.module';
import sectionsModule from './modules/sections.module';
import systemAdministratorsModule from './modules/systemadministrators.module';
import systemDocumentsModule from './modules/systemdocuments.module';
import systemSettingsModule from './modules/systemsettings.module';
import taskTypesModule from './modules/tasktypes.module';
import wordCategoriesModule from './modules/wordcategories.module';
import worksheetsModule from './modules/worksheets.module';
import usersModule from './modules/users.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account: accountModule,
    applicationSettings: applicationSettingsModule,
    authentication: authenticationModule,
    cases: casesModule,
    cart: cartModule,
    catalogue: catalogueModule,
    downloadablePacks: downloadablePacksModule,
    error: errorModule,
    items: itemsModule,
    organisations: organisationsModule,
    sections: sectionsModule,
    systemAdministrators: systemAdministratorsModule,
    systemDocuments: systemDocumentsModule,
    systemSettings: systemSettingsModule,
    taskTypes: taskTypesModule,
    wordCategories: wordCategoriesModule,
    worksheets: worksheetsModule,
    users: usersModule,
  },
});
