<template>
  <div>
    <h3>Change Your Password</h3>
    <b-card
      tag="article"
      style="max-width: 600px;"
      class="mb-2">
        <b-form
          v-on:submit="onSubmit">
          <b-form-group id="currentpassword-group"
            label="Enter current password:"
            label-for="currentpassword-input">
            <b-form-input
              id="currentpassword-input"
              type="password"
              placeholder="Enter current password"
              required
              v-model="currentPassword">
            </b-form-input>
          </b-form-group>

          <b-form-group id="newpassword-group"
            label="Choose new password:"
            label-for="newpassword-input">
            <b-form-input
              id="newpassword-input"
              type="password"
              placeholder="Enter a new password"
              required
              v-model="newPassword">
            </b-form-input>
            <b-form-invalid-feedback v-bind:state="newPasswordIsValid">
              Password does not meet complexity requirements. See guidance below.
            </b-form-invalid-feedback>
            <b-form-text id="newpassword-help-block">
              New password must be at least 6 characters long and must contain at least one:
              <br />- uppercase character
              <br />- lowercase character
              <br />- digit
              <br />- non-alphanumeric character
            </b-form-text>
          </b-form-group>
          <b-form-group id="newpasswordconfirm-group"
            label="Confirm new password:"
            label-for="confirm-newpassword-input">
            <b-form-input
              id="confirm-newpassword-input"
              type="password"
              placeholder="Confirm new password"
              required
              v-model="confirmNewPassword">
            </b-form-input>
            <b-form-invalid-feedback v-bind:state="confirmNewPasswordIsValid">
              Passwords must match.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" variant="primary"
            v-bind:disabled="submitButtonIsDisabled">
            Change Password
            <span v-if="isLoading"><b-spinner label="Spinning" small></b-spinner></span>
            <span v-else><b-icon-box-arrow-in-right /></span>
          </b-button>
        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChangePassword',
  data() {
    return {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.account.isLoading;
    },
    newPasswordIsValid() {
      if (this.newPassword) {
        // Regex from: https://www.section.io/engineering-education/password-strength-checker-javascript/
        const regEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
        if (!regEx.test(this.newPassword)) {
          return false;
        }
      }
      return true;
    },
    confirmNewPasswordIsValid() {
      return this.confirmNewPassword === this.newPassword;
    },
    submitButtonIsDisabled() {
      return this.isLoading
      || !this.currentPassword
      || !this.newPassword
      || !this.confirmNewPassword
      || !this.newPasswordIsValid
      || !this.confirmNewPasswordIsValid;
    },
  },
  methods: {
    ...mapActions('account', ['changePassword']),
    ...mapActions('authentication', ['logout']),
    onSubmit(event) {
      event.preventDefault();

      if (this.newPasswordIsValid && this.confirmNewPasswordIsValid) {
        this.confirmChangePassword();
      }
    },
    confirmChangePassword() {
      this.$store.dispatch('error/dismissError');

      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          'Changing your password will trigger a logout from the system. You can then log in again using your new password.',
        ]),
        this.$createElement('p', [
          'Confirm that you want to change your password?',
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Confirm change password',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then((value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            this.doChangePassword();
          }
        });
    },
    async doChangePassword() {
      const changePasswordRequest = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };

      await this.changePassword(changePasswordRequest)
        .then(() => {
          this.logout()
            .then(() => {
              this.$router.push('/login');
            });
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
  },
};
</script>
