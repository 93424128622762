<template>
  <div>
    <h2>Confirm account</h2>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <p>Please click the button below to confirm your account.</p>
      <p>
        <b-button variant="primary"
        v-on:click="confirmClick">
          Confirm account
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Confirm',
  props: {
    emailAddress: { type: String, required: true },
    token: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      passwordResetToken: '',
    };
  },
  methods: {
    ...mapActions('authentication', ['confirmAccount']),
    confirmClick() {
      this.tryConfirm();
    },
    async tryConfirm() {
      this.isLoading = true;

      await this.confirmAccount({
        emailAddress: this.emailAddress,
        confirmationToken: this.token,
      })
        .then((response) => {
          this.passwordResetToken = response.data.passwordResetToken;
          this.$router.replace(`/authentication/resetpassword?email=${this.emailAddress}&token=${this.passwordResetToken}&new=true`);
        })
        .catch((error) => {
          this.handleHttpError(error);
        });

      this.isLoading = false;
    },
  },
};
</script>
