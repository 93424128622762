export default {
  namespaced: true,
  state: {
    hasError: false,
    errorTitle: '',
    errorMessage: '',
    errorDetails: null,
    httpStatusCode: '',
  },
  getters: {
  },
  mutations: {
    setError(state, error) {
      state.hasError = true;
      state.errorTitle = error.errorTitle;
      state.errorMessage = error.errorMessage;
      state.errorDetails = error.errorDetails;
      state.httpStatusCode = error.httpStatusCode;
    },
    clearError(state) {
      state.hasError = false;
      state.errorTitle = '';
      state.errorMessage = '';
      state.errorDetails = null;
      state.httpStatusCode = '';
    },
  },
  actions: {
    dismissError(context) {
      context.commit('clearError');
    },
    showError(context, error) {
      context.commit('setError', error);
    },
  },
};
