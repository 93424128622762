<template>
  <div>
    <div class="mb-3">
      <h5>Manage Letter Case</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div>
        <b-table striped bordered hover
          stacked="sm"
          v-bind:items="cases"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editCaseClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteCaseClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addCaseClick">
          Add Letter Case <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Cases',
  created() {
    this.initialiseUI();
  },
  data() {
    return {
      tableFields: [
        { key: 'name', thStyle: { width: '75%' } },
        { key: 'actions', thStyle: { width: '25%' } },
      ],
      isInitialisingUI: false,
    };
  },
  computed: {
    cases() {
      return this.$store.state.cases.cases;
    },
    tableIsBusy() {
      return this.$store.state.cases.isLoading;
    },
    addButtonIsDisabled() {
      return this.$store.state.cases.isLoading;
    },
  },
  methods: {
    ...mapActions('cases', ['getCases', 'deleteCase']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.getCases()
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('cases/clearState');
        });

      this.isInitialisingUI = false;
    },
    addCaseClick() {
      this.$router.push({
        name: 'Admin-LetterCaseDetails',
        params: {
          caseInstance: { id: 0, name: '' },
          new: true,
        },
      });
    },
    editCaseClick(caseInstance) {
      this.$router.push({
        name: 'Admin-LetterCaseDetails',
        params: {
          caseInstance,
          new: false,
        },
      });
    },
    async deleteCaseClick(c) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${c.name}"?`, {
          title: 'Delete Letter Case',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteCase(c.id)
              .then(async () => { await this.getCases(); })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
