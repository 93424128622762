<!-- eslint-disable max-len -->
<template>
  <div class="text-center p-4">
    <AphasiaTherapyResourcesLogo />
    <p>
      Welcome to the Communicative Writing Resources.
      As a reminder, the resources are designed to be used in conjunction with the
      <b-link v-on:click="downloadManualDocument">manual
        <b-spinner v-if="isDownloadingInstructionsFile" label="Spinning" small />
        <b-icon-download v-else />
      </b-link>
    </p>
    <div class="mt-5">
      <b-button variant="primary"
        class="mr-2"
        v-on:click="goToCatalogueClick">
        Go to Catalogue
      </b-button>
    </div>
    <h3 class="mt-5">
      Acknowledgements
    </h3>
    <p>
      This web application was designed by the NUIT Software Development Team.
      <br/>
      We would also like to thank the many people with aphasia, Speech and Language Therapists and students who have attended and worked in the Tavistock Aphasia Centre and who shaped these resources.
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fileHandlerMixin from '../mixins/fileHandlerMixin';
import AphasiaTherapyResourcesLogo from '../components/AphasiaTherapyResourcesLogo.vue';

export default {
  name: 'Home',
  mixins: [fileHandlerMixin],
  components: { AphasiaTherapyResourcesLogo },
  computed: {
    isDownloadingInstructionsFile() {
      return this.$store.state.systemDocuments.isDownloadingFile;
    },
  },
  methods: {
    ...mapActions('systemDocuments', ['getManualDocument']),
    async downloadManualDocument() {
      await this.getManualDocument()
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    goToCatalogueClick() {
      this.$router.push('/catalogue');
    },
  },
};
</script>
