<template>
  <div class="mb-3">
    <b-img fluid v-bind:src="require('../assets/aphasiatherapyresourceslogo.png')" />
  </div>
</template>

<script>
export default {
  name: 'TheLogo',
};
</script>
