<template>
  <div>
    <b-card
      v-bind:title="title"
      tag="article"
      style="max-width: 600px;"
      class="mb-2">
      <div v-if="!resetPasswordSuccessful">
        <div v-if="isNewAccount">
          <p>
            Thank you - your account has been confirmed
            <b-icon-check-circle-fill variant="success" />
          </p>
          <p>Please choose a password to complete your registration.</p>
          <hr />
        </div>
        <b-form
          v-on:submit="onSubmit">
          <b-form-group id="password-group"
            label="Choose Password:"
            label-for="password-input">
            <b-form-input
              id="password-input"
              type="password"
              placeholder="Enter a password"
              required
              v-model="password">
            </b-form-input>
            <b-form-invalid-feedback v-bind:state="passwordIsValid">
              Password does not meet complexity requirements. See guidance below.
            </b-form-invalid-feedback>
            <b-form-text id="password-help-block">
              Password must be at least 6 characters long and must contain at least one:
              <br />- uppercase character
              <br />- lowercase character
              <br />- digit
              <br />- non-alphanumeric character
            </b-form-text>
          </b-form-group>
          <b-form-group id="passwordconfirm-group"
            label="Confirm Password:"
            label-for="confirm-password-input">
            <b-form-input
              id="confirm-password-input"
              type="password"
              placeholder="Confirm password"
              required
              v-model="confirmPassword">
            </b-form-input>
            <b-form-invalid-feedback v-bind:state="confirmPasswordIsValid">
              Passwords must match.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" variant="primary"
            v-bind:disabled="submitButtonIsDisabled">
            {{title}}
            <span v-if="isLoading"><b-spinner label="Spinning" small></b-spinner></span>
            <span v-else><b-icon-box-arrow-in-right /></span>
          </b-button>
        </b-form>
      </div>
      <div v-else>
        <p v-if="isNewAccount">Password set successfully. Continue to login.</p>
        <p v-else>Password reset successfully. Continue to login.</p>
        <p>
          <b-button variant="primary"
          v-on:click="goToLoginClick">
            Go to Login <b-icon-arrow-right />
          </b-button>
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  props: {
    emailAddress: { type: String, required: true },
    token: { type: String, required: true },
    isNewAccount: String,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      resetPasswordSuccessful: false,
    };
  },
  computed: {
    title() {
      if (this.isNewAccount) {
        return 'Set Password';
      }
      return 'Reset Password';
    },
    passwordIsValid() {
      if (this.password) {
        // Regex from: https://www.section.io/engineering-education/password-strength-checker-javascript/
        const regEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
        if (!regEx.test(this.password)) {
          return false;
        }
      }
      return true;
    },
    confirmPasswordIsValid() {
      return this.confirmPassword === this.password;
    },
    isLoading() {
      return this.$store.state.authentication.isLoading;
    },
    submitButtonIsDisabled() {
      return this.isLoading
      || !this.password
      || !this.confirmPassword
      || !this.passwordIsValid
      || !this.confirmPasswordIsValid;
    },
  },
  methods: {
    ...mapActions('authentication', ['resetUserPassword']),
    onSubmit(event) {
      event.preventDefault();

      if (this.passwordIsValid && this.confirmPasswordIsValid) {
        this.doResetPassword();
      }
    },
    async doResetPassword() {
      this.$store.dispatch('error/dismissError');

      await this.resetUserPassword({
        emailAddress: this.emailAddress,
        password: this.password,
        resetPasswordToken: this.token,
      })
        .then(() => {
          this.resetPasswordSuccessful = true;
        })
        .catch((error) => {
          this.resetPasswordSuccessful = false;
          this.handleHttpError(error);
        });
    },
    goToLoginClick() {
      this.$router.replace('/login');
    },
  },
};
</script>
