<template>
  <div>
    <div class="mb-3">
      <h5>Manage Worksheets</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-card>
        <AdminDropdown for="sections"
          preventSelectDefaultItem
          v-on:dropdownItemChanged="selectedSectionChanged" />
        <AdminDropdown for="wordcategories"
          v-on:dropdownItemChanged="selectedWordCategoryChanged" />
        <AdminDropdown for="items"
          v-on:dropdownItemChanged="selectedItemChanged" />
        <AdminDropdown for="tasktypes"
          v-on:dropdownItemChanged="selectedTaskTypeChanged" />
        <AdminDropdown for="lettercases"
          v-on:dropdownItemChanged="selectedCaseChanged" />
      </b-card>
      <div>
        <b-table striped bordered hover
          stacked="lg"
          v-bind:items="worksheets"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Download Document"
              v-on:click="downloadDocumentClick(data.item)">
              <b-icon-download />
            </b-button>
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editWorksheetClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteWorksheetClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalWorksheetsCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addWorksheetClick">
          Add Worksheet <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'Worksheets',
  components: { AdminDropdown },
  mixins: [fileHandlerMixin],
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '20%' } },
        { key: 'caseName', label: 'Letter Case', thStyle: { width: '20%' } },
        { key: 'itemName', label: 'Item', thStyle: { width: '20%' } },
        { key: 'taskTypeName', label: 'Task Type', thStyle: { width: '20%' } },
        { key: 'actions', thStyle: { width: '20%' } },
      ],
      isInitialisingUI: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    sections() {
      return this.$store.state.sections.sections;
    },
    wordCategories() {
      return this.$store.state.wordCategories.wordCategories;
    },
    items() {
      return this.$store.state.items.items;
    },
    taskTypes() {
      return this.$store.state.taskTypes.taskTypes;
    },
    cases() {
      return this.$store.state.cases.cases;
    },
    worksheets() {
      return this.$store.state.worksheets.worksheets;
    },
    totalWorksheetsCount() {
      return this.$store.state.worksheets.totalWorksheetsCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.worksheets.isLoading
      || this.$store.state.worksheets.isDownloadingFile;
    },
    addButtonIsDisabled() {
      return this.$store.state.worksheets.isLoading
      || this.$store.state.worksheets.isDownloadingFile
      || this.$store.state.sections.selectedSectionId === null;
    },
    searchWorksheetsParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
        caseId: this.$store.state.cases.selectedCaseId,
        itemId: this.$store.state.items.selectedItemId,
        sectionId: this.$store.state.sections.selectedSectionId,
        taskTypeId: this.$store.state.taskTypes.selectedTaskTypeId,
        wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
      };
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('wordCategories', ['searchWordCategories']),
    ...mapActions('items', ['searchItems']),
    ...mapActions('taskTypes', ['searchTaskTypes']),
    ...mapActions('cases', ['getCases']),
    ...mapActions('worksheets', ['searchWorksheets', 'deleteWorksheet', 'getWorksheetDocument']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections();

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchWordCategories({
            sectionId: this.$store.state.sections.selectedSectionId,
          });

          if (this.$store.state.wordCategories.selectedWordCategoryId) {
            await this.searchItems({
              wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
            });
          } else {
            await this.searchItems({
              sectionId: this.$store.state.sections.selectedSectionId,
            });
          }

          await this.searchTaskTypes({ sectionId: this.$store.state.sections.selectedSectionId });
          await this.getCases();
          await this.searchWorksheets(this.searchWorksheetsParams);
        } else {
          this.$store.dispatch('wordCategories/clearState');
          this.$store.dispatch('items/clearState');
          this.$store.dispatch('taskTypes/clearState');
          this.$store.dispatch('cases/clearState');
          this.$store.dispatch('worksheets/clearState');
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('taskTypes/clearState');
        this.$store.dispatch('cases/clearState');
        this.$store.dispatch('worksheets/clearState');
      }

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchWorksheets(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    async selectedSectionChanged() {
      this.pageNumber = 1;

      try {
        await this.searchWordCategories({
          sectionId: this.$store.state.sections.selectedSectionId,
        });
        await this.searchItems({ sectionId: this.$store.state.sections.selectedSectionId });
        await this.searchTaskTypes({ sectionId: this.$store.state.sections.selectedSectionId });
        await this.getCases();
        await this.searchWorksheets(this.searchWorksheetsParams);
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('cases/clearState');
        this.$store.dispatch('taskTypes/clearState');
        this.$store.dispatch('worksheets/clearState');
      }
    },
    async selectedWordCategoryChanged() {
      this.pageNumber = 1;

      try {
        if (this.$store.state.wordCategories.selectedWordCategoryId) {
          await this.searchItems({
            wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
          });
        } else {
          await this.searchItems({ sectionId: this.$store.state.sections.selectedSectionId });
        }
        await this.searchWorksheets(this.searchWorksheetsParams);
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('worksheets/clearState');
      }
    },
    async selectedItemChanged() {
      this.pageNumber = 1;
      await this.searchWorksheets(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    async selectedTaskTypeChanged() {
      this.pageNumber = 1;
      await this.searchWorksheets(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    async selectedCaseChanged() {
      this.pageNumber = 1;
      await this.searchWorksheets(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    addWorksheetClick() {
      this.$router.push({
        name: 'Admin-WorksheetDetails',
        params: {
          worksheet: {
            id: 0,
            name: '',
            caseId: this.$store.state.cases.selectedCaseId,
            wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
            itemId: this.$store.state.items.selectedItemId,
            sectionId: this.$store.state.sections.selectedSectionId,
            taskTypeId: this.$store.state.taskTypes.selectedTaskTypeId,
          },
          new: true,
        },
      });
    },
    editWorksheetClick(worksheet) {
      this.$router.push({
        name: 'Admin-WorksheetDetails',
        params: {
          worksheet,
          new: false,
        },
      });
    },
    async downloadDocumentClick(worksheet) {
      await this.getWorksheetDocument(worksheet.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async deleteWorksheetClick(worksheet) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${worksheet.name}"?`, {
          title: 'Delete Worksheet',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteWorksheet(worksheet.id)
              .then(async () => {
                this.pageNumber = 1;
                await this.searchWorksheets(this.searchWorksheetsParams);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
