<template>
  <div>
    <div class="mb-3">
      <h5>Manage Word Categories</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-card>
        <AdminDropdown for="sections"
          preventSelectDefaultItem
          v-on:dropdownItemChanged="selectedSectionChanged" />
      </b-card>
      <div>
        <b-table striped bordered hover
          stacked="sm"
          v-bind:items="wordCategories"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editWordCategoryClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteWordCategoryClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalWordCategoriesCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addWordCategoryClick">
          Add Word Category <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';

export default {
  name: 'WordCategories',
  components: { AdminDropdown },
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '75%' } },
        { key: 'actions', thStyle: { width: '25%' } },
      ],
      isInitialisingUI: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    wordCategories() {
      return this.$store.state.wordCategories.wordCategories;
    },
    totalWordCategoriesCount() {
      return this.$store.state.wordCategories.totalWordCategoriesCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.wordCategories.isLoading;
    },
    addButtonIsDisabled() {
      return this.$store.state.wordCategories.isLoading
      || this.$store.state.sections.selectedSectionId === null;
    },
    searchParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
        sectionId: this.$store.state.sections.selectedSectionId,
      };
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('wordCategories', ['searchWordCategories', 'deleteWordCategory']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections();

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchWordCategories(this.searchParams);
        } else {
          this.$store.dispatch('wordCategories/clearState');
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('wordCategories/clearState');
      }

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchWordCategories(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('wordCategories/clearState');
        });
    },
    async selectedSectionChanged() {
      this.pageNumber = 1;
      await this.searchWordCategories(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('wordCategories/clearState');
        });
    },
    addWordCategoryClick() {
      this.$router.push({
        name: 'Admin-WordCategoryDetails',
        params: {
          wordCategory: { id: 0, name: '', sectionId: this.$store.state.sections.selectedSectionId },
          new: true,
        },
      });
    },
    editWordCategoryClick(wordCategory) {
      this.$router.push({
        name: 'Admin-WordCategoryDetails',
        params: {
          wordCategory,
          new: false,
        },
      });
    },
    async deleteWordCategoryClick(wordCategory) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${wordCategory.name}"?`, {
          title: 'Delete Word Category',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteWordCategory(wordCategory.id)
              .then(async () => {
                this.pageNumber = 1;
                await this.searchWordCategories(this.searchParams);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
