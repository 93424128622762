<template>
  <div>
    <h3>Page Not Found</h3>

    <b-card>
      <p>
        Sorry, the page you have requested could not be found.
      </p>
      <div>
        <b-button variant="primary"
        v-on:click="buttonClick">
          Home
        </b-button>
      </div>
    </b-card>

  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    buttonClick() {
      this.$router.replace({ path: '/' });
    },
  },
};
</script>
