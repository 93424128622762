<template>
  <div>
    <b-alert variant="danger"
      class="rounded-0"
      dismissible
      v-bind:show="hasError"
      v-on:dismissed="onDismissed">
      <h4 class="alert-heading">
        <b-icon-exclamation-triangle /> {{errorTitle}}
      </h4>
      <p>{{errorMessage}}</p>
      <div v-if="errorDetails">
        Further details:
        <ul>
          <li v-for="errorDetail in errorDetails" v-bind:key="errorDetail">
            {{errorDetail}}
          </li>
        </ul>
      </div>
      <hr>
      <p v-if="httpStatusCode" class="mb-0 status-code">
        HTTP Status Code: {{httpStatusCode}}
      </p>
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TheErrorAlert',
  computed: {
    ...mapState('error', ['hasError', 'errorTitle', 'errorMessage', 'errorDetails', 'httpStatusCode']),
  },
  methods: {
    ...mapActions('error', ['dismissError']),
    onDismissed() {
      this.dismissError();
    },
  },
};
</script>

<style scoped>
.status-code {
  font-size: 12px;
  text-align: right;
}
</style>
