<template>
  <div>
    <BroadcastMessage />
    <div class="row">
      <div class="col-md-7">
        <LoginContent />
      </div>
      <div class="col-md-5">
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import BroadcastMessage from '../components/BroadcastMessage.vue';
import LoginForm from '../components/LoginForm.vue';
import LoginContent from '../components/LoginContent.vue';

export default {
  name: 'Login',
  components: { BroadcastMessage, LoginForm, LoginContent },
};
</script>
