import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

// Components:
import Access from '../views/AdminAccess.vue';
import Admin from '../views/Admin.vue';
import Cart from '../views/Cart.vue';
import Cases from '../views/CasesList.vue';
import CaseDetails from '../views/CaseDetails.vue';
import Catalogue from '../views/Catalogue.vue';
import ChangePassword from '../views/ChangePassword.vue';
import ConfirmAccount from '../views/ConfirmAccount.vue';
import Content from '../views/AdminContent.vue';
import ForgottenPassword from '../views/ForgottenPassword.vue';
import Home from '../views/Home.vue';
import Items from '../views/ItemsList.vue';
import ItemDetails from '../views/ItemDetails.vue';
import Login from '../views/Login.vue';
import NotFound from '../views/NotFound.vue';
import Organisations from '../views/OrganisationsList.vue';
import OrganisationAdmin from '../views/OrganisationAdmin.vue';
import OrganisationDetails from '../views/OrganisationDetails.vue';
import OrganisationUsers from '../views/OrganisationUsersList.vue';
import OrganisationUserDetails from '../views/OrganisationUserDetails.vue';
import MyDownloadablePacks from '../views/MyDownloadablePacksList.vue';
import MyDownloadablePackDetails from '../views/MyDownloadablePackDetails.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Sections from '../views/SectionsList.vue';
import SectionDetails from '../views/SectionDetails.vue';
import System from '../views/AdminSystem.vue';
import SystemAdministrators from '../views/SystemAdministratorsList.vue';
import SystemAdministratorDetails from '../views/SystemAdministratorDetails.vue';
import SystemDocuments from '../views/SystemDocuments.vue';
import SystemSettings from '../views/SystemSettings.vue';
import TaskTypes from '../views/TaskTypesList.vue';
import TaskTypeDetails from '../views/TaskTypeDetails.vue';
import WordCategories from '../views/WordCategoriesList.vue';
import WordCategoryDetails from '../views/WordCategoryDetails.vue';
import Worksheets from '../views/WorksheetsList.vue';
import WorksheetDetails from '../views/WorksheetDetails.vue';
import Users from '../views/UsersList.vue';
import UserDetails from '../views/UserDetails.vue';

Vue.use(VueRouter);

const systemTitle = 'Communicative Writing Resources';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' },
  },
  {
    path: '/catalogue',
    name: 'Catalogue',
    component: Catalogue,
    meta: { title: 'Catalogue' },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: { title: 'Cart' },
  },
  {
    path: '/mydownloadablepacks',
    name: 'MyDownloadablePacks',
    component: MyDownloadablePacks,
    meta: { title: 'My Downloadable Packs' },
  },
  {
    path: '/mydownloadablepacks/details',
    name: 'MyDownloadablePackDetails',
    component: MyDownloadablePackDetails,
    props: true,
    meta: {
      title: 'My Downloadable Pack Details',
    },
  },
  {
    path: '/account/changepassword',
    name: 'Account-ChangePassword',
    component: ChangePassword,
    meta: { title: 'Account - Change Password' },
  },
  // BEGIN: Anonymous Authentication pages
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Log In',
      allowAnonymous: true,
    },
  },
  {
    path: '/authentication/confirm',
    name: 'ConfirmAccount',
    component: ConfirmAccount,
    props: (route) => ({
      emailAddress: route.query.email,
      token: route.query.token,
    }),
    meta: {
      title: 'Confirm Account',
      allowAnonymous: true,
    },
  },
  {
    path: '/authentication/forgottenpassword',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    meta: {
      title: 'Forgotten Password',
      allowAnonymous: true,
    },
  },
  {
    path: '/authentication/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    props: (route) => ({
      emailAddress: route.query.email,
      token: route.query.token,
      isNewAccount: route.query.new,
    }),
    meta: {
      title: 'Reset Password',
      allowAnonymous: true,
    },
  },
  // END: Anonymous Authentication pages
  // BEGIN: System Admin pages
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: {
      name: 'Content',
    },
    children: [
      {
        path: 'content',
        name: 'Content',
        component: Content,
        redirect: { name: 'Admin-Sections' },
        children: [
          // System Admin - Content sub-pages
          {
            path: 'sections',
            name: 'Admin-Sections',
            component: Sections,
            meta: {
              title: 'Admin - Sections',
              allowedRoles: [1],
            },
          },
          {
            path: 'sections/details',
            name: 'Admin-SectionDetails',
            component: SectionDetails,
            props: true,
            meta: {
              title: 'Admin - Section Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'wordcategories',
            name: 'Admin-WordCategories',
            component: WordCategories,
            meta: {
              title: 'Admin - Word Categories',
              allowedRoles: [1],
            },
          },
          {
            path: 'wordcategories/details',
            name: 'Admin-WordCategoryDetails',
            component: WordCategoryDetails,
            props: true,
            meta: {
              title: 'Admin - Word Category Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'items',
            name: 'Admin-Items',
            component: Items,
            meta: {
              title: 'Admin - Items',
              allowedRoles: [1],
            },
          },
          {
            path: 'items/details',
            name: 'Admin-ItemDetails',
            component: ItemDetails,
            props: true,
            meta: {
              title: 'Admin - Item Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'tasktypes',
            name: 'Admin-TaskTypes',
            component: TaskTypes,
            meta: {
              title: 'Admin - Task Types',
              allowedRoles: [1],
            },
          },
          {
            path: 'tasktypes/details',
            name: 'Admin-TaskTypeDetails',
            component: TaskTypeDetails,
            props: true,
            meta: {
              title: 'Admin - Task Type Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'worksheets',
            name: 'Admin-Worksheets',
            component: Worksheets,
            meta: {
              title: 'Admin - Worksheets',
              allowedRoles: [1],
            },
          },
          {
            path: 'worksheets/details',
            name: 'Admin-WorksheetDetails',
            component: WorksheetDetails,
            props: true,
            meta: {
              title: 'Admin - Worksheet Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'lettercase',
            name: 'Admin-LetterCase',
            component: Cases,
            meta: {
              title: 'Admin - Letter Case',
              allowedRoles: [1],
            },
          },
          {
            path: 'lettercase/details',
            name: 'Admin-LetterCaseDetails',
            component: CaseDetails,
            props: true,
            meta: {
              title: 'Admin - Letter Case Details',
              allowedRoles: [1],
            },
          },
        ],
      },
      {
        path: 'access',
        name: 'Access',
        component: Access,
        redirect: {
          name: 'Admin-SystemAdministrators',
        },
        children: [
          // System Admin - Access sub-pages
          {
            path: 'systemadministrators',
            name: 'Admin-SystemAdministrators',
            component: SystemAdministrators,
            meta: {
              title: 'Admin - System Administrators',
              allowedRoles: [1],
            },
          },
          {
            path: 'systemadministrators/details',
            name: 'Admin-SystemAdministratorDetails',
            component: SystemAdministratorDetails,
            props: true,
            meta: {
              title: 'Admin - System Administrator Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'organisations',
            name: 'Admin-Organisations',
            component: Organisations,
            meta: {
              title: 'Admin - Organisations',
              allowedRoles: [1],
            },
          },
          {
            path: 'organisations/details',
            name: 'Admin-OrganisationDetails',
            component: OrganisationDetails,
            props: true,
            meta: {
              title: 'Admin - Organisation Details',
              allowedRoles: [1],
            },
          },
          {
            path: 'users',
            name: 'Admin-Users',
            component: Users,
            meta: {
              title: 'Admin - Users',
              allowedRoles: [1],
            },
          },
          {
            path: 'users/details',
            name: 'Admin-UserDetails',
            component: UserDetails,
            props: true,
            meta: {
              title: 'Admin - User Details',
              allowedRoles: [1],
            },
          },
        ],
      },
      {
        path: 'system',
        name: 'System',
        component: System,
        redirect: {
          name: 'Admin-SystemSettings',
        },
        children: [
          // System Admin - System sub-pages
          {
            path: 'systemsettings',
            name: 'Admin-SystemSettings',
            component: SystemSettings,
            meta: {
              title: 'Admin - System Settings',
              allowedRoles: [1],
            },
          },
          {
            path: 'systemdocuments',
            name: 'Admin-SystemDocuments',
            component: SystemDocuments,
            meta: {
              title: 'Admin - System Documents',
              allowedRoles: [1],
            },
          },
        ],
      },
    ],
  },
  // END: System Admin pages
  // BEGIN: Organisation Admin pages
  {
    path: '/organisationadmin',
    name: 'OrganisationAdmin',
    component: OrganisationAdmin,
    redirect: {
      name: 'OrganisationAdmin-Users',
    },
    children: [
      {
        path: 'users',
        name: 'OrganisationAdmin-Users',
        component: OrganisationUsers,
        meta: {
          title: 'Organisation Admin - Users',
          allowedRoles: [2],
        },
      },
      {
        path: 'users/details',
        name: 'OrganisationAdmin-UserDetails',
        component: OrganisationUserDetails,
        props: true,
        meta: {
          title: 'Organisation Admin - User Details',
          allowedRoles: [2],
        },
      },
    ],
  },
  // END: Organisation Admin pages
  {
    // Catch-all route, surfaces a 'not found' page:
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: {
      title: 'Page Not Found',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Clear any lingering error messages when navigating pages:
  store.dispatch('error/dismissError');

  // Allow unrestricted access to routes labelled as anonymous:
  if (to.meta.allowAnonymous) {
    return next();
  }

  // Restrict access to routes that have allowedRoles meta:
  if (to.meta.allowedRoles) {
    // If any of the allowedRoles belong to the logged-in user, allow access to the route:
    if (to.meta.allowedRoles.some((role) => store.getters['authentication/isLoggedInUserInRole'](role))) {
      return next();
    }
    return next('/');
  }

  // Ensure that only logged-in users can access any other routes:
  if (store.getters['authentication/isLoggedIn']) {
    return next();
  }

  return next('/login');
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    // Apply page titles from route meta:
    document.title = to.meta.title ? `${systemTitle}: ${to.meta.title}` : systemTitle;
  });
});

export default router;
