export default {
  methods: {
    openFile(fileResponse) {
      // Parse the headers to get the filename:
      const contentDisposition = fileResponse.headers['content-disposition'];
      const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
      const filename = match[1];

      // Construct a blob object:
      const blob = new Blob(
        [fileResponse.data],
        { type: `application/${fileResponse.headers['content-type']}` },
      );

      // Generate a link in the DOM to the blob, then click it, then remove it:
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
};
