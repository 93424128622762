<template>
  <div>
    <div class="mb-3">
      <h5>Manage Worksheets - {{titleText}}</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="worksheet">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <AdminDropdown for="sections"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedSectionChanged" />
          <AdminDropdown for="wordcategories"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedWordCategoryChanged" />
          <AdminDropdown for="items"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedItemChanged" />
          <AdminDropdown for="tasktypes"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedTaskTypeChanged" />
          <AdminDropdown for="lettercases"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedCaseChanged" />

          <b-form-group label="Worksheet Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="worksheet.name"
              placeholder="Enter name"
              required>
            </b-form-input>
          </b-form-group>

          <div v-if="isNew">
            <b-form-group label="Worksheet document (PDF):"
              label-cols-lg="2"
              content-cols-lg="10">
              <b-form-file
                required
                accept="application/pdf"
                v-model="worksheet.file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here...">
              </b-form-file>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group id="input-group-8"
            label="Uploaded Worksheet document:">
              <b-link id="input-8" v-on:click="openFile">
                Worksheet Document
                <b-spinner v-if="isDownloadingFile" label="Spinning" small />
                <b-icon-download v-else />
              </b-link>
            </b-form-group>
            <b-form-group id="input-group-9">
              <b-form-checkbox id="input-9" v-model="updateExistingFile">
                  Change uploaded file?
                </b-form-checkbox>
            </b-form-group>
            <b-collapse v-model="updateExistingFile" class="mt-2">
              <b-form-group label="Worksheet document:"
                label-cols-lg="2"
                content-cols-lg="10">
                <b-form-file
                  id="input-10"
                  accept="application/pdf"
                  v-model="worksheet.file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here...">
                </b-form-file>
              </b-form-group>
            </b-collapse>
          </div>

          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="worksheetsIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="worksheetsIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="worksheetsIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'WorksheetDetails',
  components: { AdminDropdown },
  mixins: [fileHandlerMixin],
  data() {
    return {
      isInitialisingUI: false,
      updateExistingFile: false,
    };
  },
  props: {
    worksheet: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.worksheet) {
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    sections() {
      return this.$store.state.sections.sections;
    },
    wordCategories() {
      return this.$store.state.wordCategories.wordCategories;
    },
    items() {
      return this.$store.state.items.items;
    },
    taskTypes() {
      return this.$store.state.taskTypes.taskTypes;
    },
    cases() {
      return this.$store.state.cases.cases;
    },
    wordCategoriesDropDownIsDisabled() {
      return this.$store.state.sections.selectedSectionId === null
      || this.$store.state.sections.isLoading
      || this.$store.state.wordCategories.isLoading;
    },
    itemsDropDownIsDisabled() {
      return this.$store.state.wordCategories.selectedWordCategoryId === null
      || this.$store.state.sections.isLoading
      || this.$store.state.wordCategories.isLoading
      || this.$store.state.items.isLoading;
    },
    taskTypesDropDownIsDisabled() {
      return this.$store.state.sections.selectedSectionId === null
      || this.$store.state.sections.isLoading
      || this.$store.state.taskTypes.isLoading;
    },
    casesDropDownIsDisabled() {
      return this.$store.state.sections.selectedSectionId === null
      || this.$store.state.sections.isLoading
      || this.$store.state.cases.isLoading;
    },
    worksheetsIsLoading() {
      return this.$store.state.worksheets.isLoading;
    },
    isDownloadingFile() {
      return this.$store.state.worksheets.isDownloadingFile;
    },
    titleText() {
      return this.new === true ? 'Add Worksheet' : 'Edit Worksheet';
    },
    submitText() {
      return this.new === true ? 'Add Worksheet' : 'Update Worksheet';
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('wordCategories', ['searchWordCategories']),
    ...mapActions('items', ['searchItems']),
    ...mapActions('taskTypes', ['searchTaskTypes']),
    ...mapActions('cases', ['getCases']),
    ...mapActions('worksheets', ['createWorksheet', 'updateWorksheet', 'getWorksheetDocument']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections()
          .then(() => {
            this.$store.dispatch('sections/changeSelectedSection', this.worksheet.sectionId);
          });

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchWordCategories({
            sectionId: this.$store.state.sections.selectedSectionId,
          })
            .then(() => {
              this.$store.dispatch('wordCategories/changeSelectedWordCategory', this.worksheet.wordCategoryId);
            });
        }

        if (this.$store.state.sections.selectedSectionId
          && this.$store.state.wordCategories.selectedWordCategoryId) {
          await this.searchItems({
            wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
          })
            .then(() => {
              this.$store.dispatch('items/changeSelectedItem', this.worksheet.itemId);
            });
        }

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchTaskTypes({
            sectionId: this.$store.state.sections.selectedSectionId,
          })
            .then(() => {
              this.$store.dispatch('taskTypes/changeSelectedTaskType', this.worksheet.taskTypeId);
            });
        }

        if (this.$store.state.sections.selectedSectionId) {
          await this.getCases()
            .then(() => {
              this.$store.dispatch('cases/changeSelectedCase', this.worksheet.caseId);
            });
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('taskTypes/clearState');
        this.$store.dispatch('cases/clearState');
        this.$store.dispatch('worksheets/clearState');
      }

      this.isInitialisingUI = false;
    },
    async selectedSectionChanged() {
      this.worksheet.sectionId = this.$store.state.sections.selectedSectionId;
      this.worksheet.wordCategoryId = null;
      this.worksheet.itemId = null;
      this.worksheet.taskTypeId = null;

      try {
        await this.searchWordCategories({
          sectionId: this.$store.state.sections.selectedSectionId,
        });
        await this.searchTaskTypes({
          sectionId: this.$store.state.sections.selectedSectionId,
        });
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('taskTypes/clearState');
        this.$store.dispatch('worksheets/clearState');
      }
    },
    async selectedWordCategoryChanged() {
      this.worksheet.wordCategoryId = this.$store.state.wordCategories.selectedWordCategoryId;
      this.worksheet.itemId = null;

      await this.searchItems({
        wordCategoryId: this.$store.state.wordCategories.selectedWordCategoryId,
      })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('items/clearState');
          this.$store.dispatch('worksheets/clearState');
        });
    },
    selectedItemChanged() {
      this.worksheet.itemId = this.$store.state.items.selectedItemId;
    },
    selectedTaskTypeChanged() {
      this.worksheet.taskTypeId = this.$store.state.taskTypes.selectedTaskTypeId;
    },
    selectedCaseChanged() {
      this.worksheet.caseId = this.$store.state.cases.selectedCaseId;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createWorksheet(this.worksheet)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateWorksheet(this.worksheet)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
    async openFile() {
      await this.getWorksheetDocument(this.worksheet.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
