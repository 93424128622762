<template>
  <div>
    <h3>My Downloadable Packs</h3>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-table striped bordered hover
        stacked="lg"
        v-bind:items="downloadablePacks"
        v-bind:fields="tableFields"
        v-bind:busy="tableIsBusy"
        v-bind:no-local-sorting="true"
        v-on:sort-changed="onSortingChanged"
        show-empty
        empty-text="No data to show">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Download"
              v-on:click="downloadClick(data.item)">
              <b-icon-download />
            </b-button>
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editDownloadablePackClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteDownloadablePackClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
      </b-table>
      <b-pagination
        v-model="pageNumber"
        v-bind:total-rows="totalDownloadablePacksCount"
        v-bind:per-page="itemsCountPerPage"
        v-bind:disabled="tableIsBusy"
        v-on:change="onPageChange"
        align="center">
      </b-pagination>

      <div class="text-center">
        <b-button variant="secondary"
          class="mr-2"
          v-on:click="goToCatalogueClick">
          Go to Catalogue
        </b-button>
      </div>
    </div>

    <b-modal
      title="Download"
      size="lg"
      v-model="showDownloadModal"
      v-on:hidden="downloadModalHidden"
      v-on:ok="downloadModalHandleOk">
      <p>
        By default, Worksheets in the Downloadable Pack will be collated in alphabetical order.
      </p>
      <p>
        Would you prefer to randomise the order?
      </p>
      <hr />
      <b-form-checkbox v-model="randomiseWorksheetOrder">
        Randomise Worksheet order
      </b-form-checkbox>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'MyDownloadablePacks',
  mixins: [fileHandlerMixin],
  data() {
    return {
      pageNumber: 1,
      sortBy: null,
      isSortByDescending: null,
      tableFields: [
        {
          key: 'name', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'taskTypeName', label: 'Task Type', thStyle: { width: '15%' },
        },
        {
          key: 'createdDateTime', label: 'Created', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'lastDownloaded', label: 'Last Downloaded', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'worksheetsCount', label: 'Worksheets', thStyle: { width: '15%' },
        },
        {
          key: 'actions', thStyle: { width: '15%' },
        },
      ],
      isInitialisingUI: false,
      showDownloadModal: false,
      selectedDownloadablePackId: null,
      randomiseWorksheetOrder: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    downloadablePacks() {
      return this.$store.state.downloadablePacks.downloadablePacks;
    },
    totalDownloadablePacksCount() {
      return this.$store.state.downloadablePacks.totalDownloadablePacksCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.downloadablePacks.isLoading
      || this.$store.state.downloadablePacks.isDownloadingFile;
    },
    listMyDownloadablePacksParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
        sortBy: this.sortBy,
        isSortByDescending: this.isSortByDescending,
      };
    },
  },
  methods: {
    ...mapActions('downloadablePacks', ['getMyDownloadablePacks', 'deleteDownloadablePack', 'getDownloadablePackDocument']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.getMyDownloadablePacks(this.listMyDownloadablePacksParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('downloadablePacks/clearState');
        });

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.getMyDownloadablePacks(this.listMyDownloadablePacksParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('downloadablePacks/clearState');
        });
    },
    async onSortingChanged(context) {
      this.pageNumber = 1;
      this.sortBy = context.sortBy;
      this.isSortByDescending = context.sortDesc;

      await this.getMyDownloadablePacks(this.listMyDownloadablePacksParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('downloadablePacks/clearState');
        });
    },
    editDownloadablePackClick(downloadablePack) {
      this.$router.push({
        name: 'MyDownloadablePackDetails',
        params: {
          downloadablePack,
        },
      });
    },
    downloadClick(downloadablePack) {
      this.selectedDownloadablePackId = downloadablePack.id;
      this.showDownloadModal = true;
    },
    downloadModalHidden() {
      this.selectedDownloadablePackId = null;
      this.randomiseWorksheetOrder = false;
    },
    downloadModalHandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.doDownload(this.selectedDownloadablePackId);
      this.showDownloadModal = false;
    },
    async doDownload(downloadablePackId) {
      await this.getDownloadablePackDocument({
        downloadablePackId,
        randomiseWorksheetOrder: this.randomiseWorksheetOrder,
      })
        .then(async (response) => {
          this.openFile(response);
          await this.getMyDownloadablePacks(this.listMyDownloadablePacksParams);
        })
        .catch((error) => { this.handleHttpError(error); });
    },
    deleteDownloadablePackClick(downloadablePack) {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Warning: '),
          'This operation is permanent.',
        ]),
        this.$createElement('p', [
          `Are you sure you want to delete "${downloadablePack.name}"?`,
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Delete Downloadable Pack',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then((value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            this.doDeleteDownloadablePack(downloadablePack.id);
          }
        });
    },
    async doDeleteDownloadablePack(downloadablePackId) {
      await this.deleteDownloadablePack(downloadablePackId)
        .then(async () => {
          this.pageNumber = 1;
          await this.getMyDownloadablePacks(this.listMyDownloadablePacksParams);
        })
        .catch((error) => { this.handleHttpError(error); });
    },
    goToCatalogueClick() {
      this.$router.push('/catalogue');
    },
  },
};
</script>
