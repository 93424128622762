import axios from 'axios';

export default {
  namespaced: true,
  state: {
    taskTypes: null,
    totalTaskTypesCount: null,
    isLoading: false,
    selectedTaskTypeId: null,
    isDownloadingFile: false,
  },
  mutations: {
    setTaskTypes(state, data) {
      state.taskTypes = data?.taskTypes ?? null;
      state.totalTaskTypesCount = data?.totalTaskTypesCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedTaskTypeId(state, selectedTaskTypeId) {
      state.selectedTaskTypeId = selectedTaskTypeId;
    },
    setIsDownloadingFile(state, isDownloadingFile) {
      state.isDownloadingFile = isDownloadingFile;
    },
  },
  actions: {
    getTaskTypes(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/tasktypes')
        .then((result) => {
          context.commit('setTaskTypes', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchTaskTypes(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/tasktypes/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
          sectionId: searchParams.sectionId,
        },
      })
        .then((result) => {
          context.commit('setTaskTypes', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createTaskType(context, taskType) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('name', taskType.name);
      formData.append('sectionId', taskType.sectionId);
      formData.append('file', taskType.file);

      return axios.post('/api/tasktypes', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateTaskType(context, taskType) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('id', taskType.id);
      formData.append('name', taskType.name);
      formData.append('sectionId', taskType.sectionId);
      formData.append('file', taskType.file);

      return axios.put(`/api/tasktypes/${taskType.id}`, formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteTaskType(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/tasktypes/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getTaskTypeInstructions(context, id) {
      context.commit('setIsDownloadingFile', true);
      return axios.get(`/api/tasktypes/${id}/instructions`, {
        responseType: 'blob',
      })
        .then((response) => response)
        .catch((error) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.readAsText(error.response.data);
        }))
        .finally(() => {
          context.commit('setIsDownloadingFile', false);
        });
    },
    changeSelectedTaskType(context, selectedTaskTypeId) {
      context.commit('setSelectedTaskTypeId', selectedTaskTypeId);
    },
    clearState(context) {
      context.commit('setTaskTypes', null);
    },
  },
};
