<template>
  <div>
    <b-card
      title="Log In"
      tag="article"
      style="max-width: 400px;">
      <b-form
        v-on:submit="onSubmit">
        <b-form-group
          id="email-address-group"
          label="Email address:"
          label-for="email-input">
          <b-form-input
            id="email-input"
            type="email"
            placeholder="Enter email address"
            required
            v-model="userCredentials.emailAddress">
          </b-form-input>
        </b-form-group>

        <b-form-group id="password-group" label="Password:" label-for="password-input">
          <b-form-input
            id="password-input"
            type="password"
            placeholder="Enter password"
            required
            v-model="userCredentials.password">
          </b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" v-bind:disabled="isLoading">
          Log In
          <span v-if="isLoading"><b-spinner label="Spinning" small></b-spinner></span>
          <span v-else><b-icon-box-arrow-in-right /></span>
        </b-button>
        <b-form-text>
            <b-link v-bind:to="{name: 'ForgottenPassword'}">Forgotten password?</b-link>
        </b-form-text>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      userCredentials: {
        emailAddress: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState('authentication', ['isLoading']),
  },
  methods: {
    ...mapActions('authentication', ['login']),
    onSubmit(event) {
      event.preventDefault();
      this.doLogin();
    },
    async doLogin() {
      await this.login(this.userCredentials)
        .then(() => {
          // Redirect to homepage if successfully logged in:
          this.$router.push('/');
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
  },
};
</script>
